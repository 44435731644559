<template>
  <el-main>
    <!-- 搜索 -->
    <el-form :inline="true" :model="search" class="el-form-search" label-width="100px">
      <el-form-item label="申请时间：">
        <el-date-picker
          style="width: 240px"
          size="small"
          class="search_input"
          v-model="search.Applytime"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="门店名称：">
        <el-input size="small" v-model="search.Storename" placeholder="请输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="申请单号：">
        <el-input size="small" v-model="search.ApplicationNumber" placeholder="请输入申请单号"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="searchList">搜索</el-button>
        <el-button size="small" style="margin-right: 30px" @click="exportExcel">导出</el-button>
        <el-button size="small" type="text" @click="resetSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 显示列表分类 -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in listName" :key="index" :label="item.title + `(${item.count})`" :name="item.title == '已驳回' ? 4 : item.status"></el-tab-pane>
    </el-tabs>
    <!-- 表格 -->
    <el-table fit :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="border-left: 1px solid #e8eef1; border-right: 1px solid #e8eef1">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="200" label="申请时间" align="center">
        <template v-slot="{ row }">
          {{ row.create_time | dealDate }}
        </template>
      </el-table-column>
      <el-table-column prop="withdraw_no" label="申请单号" width="280" align="center"></el-table-column>
      <el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="订单金额" align="center">
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
      <el-table-column prop="order_count" label="结算订单数" align="center"></el-table-column>
      <el-table-column label="抽成费率" align="center">
        <template v-slot="{ row }">{{ row.rate }}%</template>
      </el-table-column>
      <el-table-column label="抽成费用" align="center">
        <template v-slot="{ row }">￥{{ row.commission }}</template>
      </el-table-column>
      <el-table-column label="可结算金额" align="center">
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column label="打款金额" align="center">
        <template v-slot="{ row }">
          {{ row.remit_amount == 0 ? '——' : row.remit_amount }}
        </template>
      </el-table-column>
      <el-table-column label="提现状态" align="center">
        <template v-slot="{ row }">
          <p
            :style="{
              color: row.status == 1 || row.status == 2 ? '#fdc37b' : '#409EFF',
              cursor: 'pointer',
            }"
          >
            {{ row.status == 0 ? '已驳回' : row.status == 1 ? '待审核' : row.status == 2 ? '待结算' : '已结算' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" header-align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="lookDetail(row)" type="text" size="smll">查看详情</el-button>
          <el-button @click="lookInfo(row)" v-if="row.status == 0 || row.status == 3" type="text" size="smll">{{ row.status == 0 ? '驳回原因' : '打款凭证' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    <el-dialog class="showInfo" :visible.sync="dialogVisible" width="52%">
      <div class="logBox">
        <el-image v-if="status == 0" :src="imgUrl"></el-image>
        <div v-if="status == 1" class="reject">
          <p>驳回原因：</p>
          <p>{{ rejectTxt }}</p>
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '../../util/getDate';
import config from '@/util/config';
export default {
  components: {
    Paging,
  },
  data: function () {
    return {
      dialogVisible: false,
      status: 0,
      imgUrl: '',
      rejectTxt: '',
      activeName: 1,
      listName: [],
      total: 0,
      page: 1,
      pageNum: 10,
      search: {
        Applytime: [],
        Storename: null,
        ApplicationNumber: null,
      },
      list: [],
    };
  },
  created() {
    this.getListType();
    this.getList();
  },
  methods: {
    lookInfo(row) {
      if (row.status == 3) {
        this.imgUrl = row.voucher;
        this.dialogVisible = true;
        this.status = 0;
      } else {
        this.rejectTxt = row.remark;
        this.dialogVisible = true;
        this.status = 1;
      }
    },
    // 分页操作
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      this.getList();
    },
    // 重置搜索信息
    resetSearch() {
      this.search = {
        Applytime: [],
        Storename: 0,
        Withdrawalstatus: 0,
      };
      this.activeName = 1;
      this.getList();
    },
    exportExcel() {
      let that = this;
      this.$axios
        .post(this.$api.store.withdrawLogExport, {
          status: that.activeName === 4 ? 0 : that.activeName,
          store_name: that.search.Storename != '' ? that.search.Storename : null,
          start_time: that.search.Applytime.length == 2 ? that.search.Applytime[0].getTime() : null,
          end_time: that.search.Applytime.length == 2 ? that.search.Applytime[1].getTime() : null,
          withdraw_no: that.search.ApplicationNumber != '' ? that.search.ApplicationNumber : null,
        })
        .then(res => {
          if (res.code == 0) {
            let path = config.baseurl + '/' + res.result;
            let a = document.createElement('a');
            a.href = path;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 获取列表信息
    getList(index) {
      let that = this;
      if (index == 1) {
        this.page == 1;
      }
      let obj = {
        page: that.page,
        rows: that.pageNum,
        status: that.activeName === 4 ? 0 : that.activeName,
        store_name: that.search.Storename != '' ? that.search.Storename : null,
        start_time: that.search.Applytime.length == 2 ? that.search.Applytime[0].getTime() : null,
        end_time: that.search.Applytime.length == 2 ? that.search.Applytime[1].getTime() : null,
        store_id: that.$route.query.id !== undefined ? that.$route.query.id : null,
        withdraw_no: that.search.ApplicationNumber != '' ? that.search.ApplicationNumber : null,
      };
      this.$axios.post(this.$api.store.withdrawLog, obj).then(res => {
        if (res.code == 0) {
          that.list = res.result.list;
          that.total = res.result.total_number;
        }
      });
    },
    // 获取列表状态数组
    getListType() {
      let that = this;
      this.$axios.post(this.$api.store.getStatus, {}).then(res => {
        if (res.code == 0) {
          that.listName = res.result;
        }
      });
    },
    handleClick(val) {
      this.getList(1);
    },
    // 点击搜索
    searchList() {
      if (this.search.Applytime.length == 0 && this.search.Storename == '' && this.search.ApplicationNumber == '') {
        this.$message({
          message: '请输入搜索条件',
          type: 'warning',
        });
        return;
      }
      this.getList(1);
    },
    // 点击查看详情
    lookDetail(item) {
      this.$router.push({
        path: `/store/Withdrawalrecord?id=${item.id}`,
      });
    },
  },
  filters: {
    dealDate(index) {
      return getDateformat(index);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.tableimg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
/deep/ .el-dialog__header {
  background-color: #f0f0f0;
}
.logBox {
  display: flex;
  justify-content: center;
  align-items: center;
  .reject {
    display: flex;
  }
}
.showInfo {
  /deep/ .el-dialog__header {
    background-color: #fff !important;
    border-bottom: 0 !important;
  }
}
</style>
